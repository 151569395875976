"use client";
import { useUiProvider } from "@/src/ui/providers/ui.provider";
import css from "./home_background.css";
import Image from "next/image";
import { useCallback, useEffect, useState } from "react";

export const HomeBackground = () => {
  const isMobile = useUiProvider((state) => state.isMobile);
  const [scrollHeight, setScrollHeight] = useState(70);
  const [imgHeight, setImgHeight] = useState(50);

  const handleScroll = useCallback(() => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;
    const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;
    setScrollHeight(-(window.scrollY - 70));
    setImgHeight(50 + scrolled / 2);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Image
      style={{ height: `${imgHeight}%`, objectPosition: `0 ${scrollHeight}px` }}
      src={isMobile ? "/assets/images/stripes_mobile.png" : "/assets/images/stripes_desktop.webp"}
      width={3000}
      height={3000}
      alt=""
      className={css.backgroundImage}
    />
  );
};
