"use client";
import type { LegacyRef, ReactNode } from "react";
import css from "./show_block.css";
import { useCallback, useEffect, useState, useRef } from "react";

export interface ShowBlockProps {
  children: ReactNode;
}

export const ShowBlock = ({ children }: ShowBlockProps) => {
  const [active, setActive] = useState(false);
  const showBlockRef = useRef<HTMLElement | undefined>(undefined);
  const handleScroll = useCallback(() => {
    if (showBlockRef.current) {
      const s = showBlockRef.current?.getBoundingClientRect().top;
      const h = window.innerHeight;
      if (s > 0 && s < h) {
        setActive(true);
      } else if (s > h) {
        setActive(false);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div ref={showBlockRef as LegacyRef<HTMLDivElement>} className={active ? css.active : css.inactive}>
      {children}
    </div>
  );
};
